$text-dark-gray : #505D68;
$text-light-gray : #A1AEB7;
$text-dark-blue: #054C70;
$mid-blue: #0089E5;

$hover-blue: #2f9ee7;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

body
{
    font-family: "Ubuntu", sans-serif;
}

.text-align{
    text-align: left;
}

.section-dark {
    background-color: $text-dark-blue;
    padding: 3rem;
    border-radius: 0 0 0 7rem;
    padding-top: 5rem;
    padding-bottom: 5rem;

}



.dropdown {
    background-color: #fff;
    border: none;
    border-radius: 0.2rem;
    height: 2rem;
    font-size: 0.9em;
    width: fit-content;

    color: #505D68;
    padding-left: 10px;
    padding-right: 10px;
    outline-color: #fff;

}

.dropdown:hover {
    background-color: rgb(200, 235, 255);
    border: none;
   
    outline-color: #fff;

    color: #505D68;
}

.dropdown:active {
    background-color: #fff;
    border: none;
  
   
    outline-color: #fff;
    color: #505D68;
}

.dropdown:focus {
    background-color: #fff;
    border: none;
   
    outline-color: #fff;
  
    color: #505D68;
}

select option:disabled {
    color: #FDFDFD;
    font-weight: 500;
   
}




.search-textbox {

    border: none;
    border-radius: 0.2rem;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 1em;

}

.join-team-para
{
    font-size: 1.2em;
    font-weight: 400;
}
.register-button {
    border: none;
    font-weight: 400;
    background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
    transition: 1s;
  }

  .register-button:hover {
    transition: 1s;
    background-image: linear-gradient(to right, #25d2fd, #054de7);
  }

  .register-button:active {
    transition: 1s;
    background-image: linear-gradient(to right, #41d2f7, #0345d3);
  }

    .secondary-button {
      color: #fff;
      text-decoration: none;
      font-weight: 500;
      background: linear-gradient(#054c70, #054c70) padding-box,
      linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%) border-box;
      border: 2px solid transparent;
      padding: 0.8em;
      width: 100%;
      border-radius: 10px;
      transition: all 0.5s;
  }
  
  
  .secondary-button:hover {
      color: #fff;
      text-decoration: none;
      font-weight: 500;
      
      border: 2px solid transparent;
      padding: 0.8em;
     
      border-radius: 10px;
      background: #0089e5;
      transition: all 0.5s;
  }
  
  .secondary-button:active {
     
      background: #026fb8;
      transition: all 0.5s;
  }
  

@media only screen and (max-width: 768px) {


    .dropdown {
      
        width:100%;
    
      
    
    }

    .text-align
    {
        text-align: center;
    }
   
  }