.footer-container {
  @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

  .font-family-class {
    font-family: "Ubuntu", sans-serif;
  }

  .policy {
    color: #424b5a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .active {
    color: #0089e5;
  }
}
