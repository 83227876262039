.navbar-container {
  .navbar-contents {
    max-width: 1400px;
    margin: auto;
  }

  .font-family-class {
    font-family: "Ubuntu", sans-serif;
  }

  .font-weight-400 {
    font-weight: 400;
  }

  .font-weight-700 {
    font-weight: 700;
  }

  @media only screen and (max-width: 1919px) {
    .navbar-contents {
      max-width: 1200px;
      margin: auto;
    }
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("./assets/expand-icon.webp") !important;
  }

  .login-btn {
    padding: 6px 20px;
    border: none;
    font-size: 1em;
    background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
  }

  .login-btn:hover {
    transition: 1s;
    background-image: linear-gradient(to right, #25d2fd, #054de7);
  }

  .login-btn:active {
    transition: 1s;
    background-image: linear-gradient(to right, #41d2f7, #0345d3);
  }

  .nav-hover {
    color: #054c70;
    transition: all 0.5s;
  }

  .nav-hover:hover {
    color: #067eb9;
    transition: all 0.5s;
  }
}
