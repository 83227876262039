.home-container {
  @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

  .font-family-class {
    font-family: "Ubuntu", sans-serif;
  }

  @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

  body {
    font-family: "Ubuntu", sans-serif;
  }

  .focus-sub-topics {
    background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
  }

  .subscribe-email {
    width: 310px;
    height: 40px;
    border: none;
    outline: 1px solid #b1b1b1;
    padding-left: 10px;
  }

  .subscribe-email:focus {
    outline: 1px solid #b1b1b1;
    padding-left: 10px;
  }

  .subscribe-email::placeholder {
    font-family: "Ubuntu", sans-serif;
    color: #a1aeb7;
    font-weight: 400;
  }

  .register-button {
    border: none;
    font-weight: 400;
    background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
    transition: 1s;
  }

  .register-button:hover {
    transition: 1s;
    background-image: linear-gradient(to right, #25d2fd, #054de7);
  }

  .register-button:active {
    transition: 1s;
    background-image: linear-gradient(to right, #41d2f7, #0345d3);
  }

  .timer-colon {
    padding-left: 0.25em;
    animation: blink-animation 1s steps(10, start) infinite;
    -webkit-animation: blink-animation 1s steps(10, start) infinite;
    transition: all 1s;
  }

  .count-container {
    width: 60%;
  }

  .carousel-indicators button {
    height: 10px !important;
    width: 10px !important;
    margin: 0 5px !important;

    border-radius: 100%;
    background-color: #1963ac !important;
  }

  .carousel-indicators {
    bottom: 5px;
    display: none;
  }

  @keyframes blink-animation {
    to {
      opacity: 0;
      transition: all 1s;
    }
  }

  @-webkit-keyframes blink-animation {
    to {
      opacity: 100%;
      transition: all 1s;
    }
  }

  @media only screen and (min-width: 1326px) {
    .section-one-row {
      height: calc(100vh - 80px);
    }

    .entc-fix {
      margin-left: 5rem;
    }

    .carousel-indicators {
      bottom: 5px;
    }
  }

  @media only screen and (min-width: 1360px) {
    .carousel-indicators {
      bottom: 10px;
    }
  }

  @media only screen and (min-width: 1920px) {
    .carousel-indicators {
      bottom: -200px;
    }
  }

  @media only screen and (max-width: 600px) {
    .count-container {
      width: 100%;
    }

    .focus-sub-topics {
      background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
      max-width: 100%;
      margin-left: 0%;
    }
  }

  @media only screen and (min-width: 992px) {
    .talent-sub-topic {
      height: 64.188px;
    }

    .section-three-contents {
      width: 80%;
    }

    .section-four-contents {
      width: 70%;
    }

    .section-divider-two-contents {
      width: 60%;
    }

    .count-container {
      width: 60%;
    }
  }
}
