.video-wrapper {
  position: relative;
  padding-bottom: 63.25%; /* 16:9 */
  padding-top: 150px;
}
.video-wrapper iframe,
.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}
.instruction-video {
  position: relative;
  padding-bottom: 53.25%; /* 16:9 */
}
.instruction-video iframe {
  width: 85%;
  height: 85%;
  border-radius: 0.8rem;
}
.course-container {
  .font-family-class {
    font-family: "Ubuntu", sans-serif;
  }

  .course-section-one {
    .short-course-btn {
      font-size: 0.8em;
      background: #0089e5;
      border-radius: 81px;
      padding: 5px 5px 5px 5px;
      border-radius: 0.5rem;
    }

    .register-btn {
      padding: 0.5em 2em;
      border: none;
      font-weight: 400;
      background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
      transition: all 0.5s;
    }

    .register-btn:hover {
      background-image: linear-gradient(to right, #25d2fd, #054de7);
      transition: all 0.5s;
    }

    .register-btn:active {
      background-image: linear-gradient(to right, #41d2f7, #0345d3);
    }

    .register-close-btn {
      padding: 0.5em 2em;
      border: none;
      font-weight: 400;
      background: linear-gradient(107.41deg, #ff0000 0%, #a50101 100%);
      transition: all 0.5s;
    }

    .register-close-btn:hover {
      background-image: linear-gradient(to right, #ff0000 0%, #970202);
      transition: all 0.5s;
    }

    .register-close-btn:active {
      background-image: linear-gradient(to right, #ff0000 0%, #8a0101);
    }

    .label-btn {
      border: none;
      font-weight: 500;
      font-size: 1em;
      transition: 0.3s;
    }

    .card {
      border: 1px solid;
      border-color: #0089e5;
      border-radius: 10px;
    }

    .timer-colon {
      padding-left: 0.25em;
      animation: blink-animation 1s steps(10, start) infinite;
      -webkit-animation: blink-animation 1s steps(10, start) infinite;
      transition: all 1s;
    }

    @keyframes blink-animation {
      to {
        opacity: 0;
        transition: all 1s;
      }
    }
    @-webkit-keyframes blink-animation {
      to {
        opacity: 100%;
        transition: all 1s;
      }
    }
  }

  .course-section-two {
    .course-list {
      list-style: none;
      margin: 0;
      position: relative;
      &:before {
        // content: "";
        display: inline-block;
        width: 2px;
        background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
        position: absolute;
        left: 35px;
        top: 12px;
        height: calc(100% - 25rem);
      }
      .course-list-item {
        position: relative;
        padding-left: 35px;
        margin-bottom: 15px;
        &:before {
          content: "";
          display: inline-block;
          width: 15px;
          height: 15px;
          background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
          border: 2px solid;
          border-color: #ffffff;
          position: absolute;
          left: -3.5px;
          top: 5px;
          border-radius: 10px;
        }
      }
    }
  }

  .right-side-arrow {
    cursor: pointer;
  }

  .right-side-arrow:hover {
    transform: scale(1.05);
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0 1px 4px);
  }

  .left-side-arrow {
    cursor: pointer;
  }

  .left-side-arrow:hover {
    transform: scale(1.05);
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0 1px 4px);
  }

  .card {
    --bs-card-border-color: none;
  }

  .list-bullet {
    float: left;
    margin-top: 6px;
    background: #0089e5;
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 100px;
    font-weight: 700;
    font-size: 25px;
    color: white;
    text-align: center;
  }

  .learn-expert {
    .expert-card {
      height: 100%;
    }

    .swiper-wrapper {
      flex-direction: column !important;
      height: 370px;
    }

    .swiper-slide {
      height: calc((100% - 90px) / 2) !important;
    }
  }

  .secondary-button {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    background: linear-gradient(#054c70, #054c70) padding-box,
      linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%) border-box;
    border: 2px solid transparent;
    padding: 0.8em;
    width: 100%;
    border-radius: 10px;
    transition: all 0.5s;
    cursor: pointer;
  }

  .secondary-button:hover {
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    border: 2px solid transparent;
    padding: 0.8em;

    border-radius: 10px;
    background: #0089e5;
    transition: all 0.5s;
  }

  .secondary-button:active {
    background: #026fb8;
    transition: all 0.5s;
  }

  .course-flyer-button{
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    border: 2px solid transparent;
    padding: 0.8em;
    cursor: pointer;
    border-radius: 10px;
    background: #0089e5;
    transition: all 0.5s;
  }
  .course-flyer-button:hover{
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    border: 2px solid transparent;
    padding: 0.8em;

    border-radius: 10px;
    background: #026fb8;
    transition: all 0.5s;
  }

  .course-flyer-button:active{
    color: #fff;
    text-decoration: none;
    font-weight: 500;

    border: 2px solid transparent;
    padding: 0.8em;

    border-radius: 10px;
    background: #015c99;
    transition: all 0.5s;
  }
  @media only screen and (min-width: 1326px) {
    /* .course-section-one {
      min-height: 100vh;
    } */

    .course-include-heading {
      height: 3rem;
    }

    .course-item-width {
      width: 22%;
    }
  }

  @media only screen and (min-width: 992px) {
    .course-include-heading {
      height: 3rem;
    }
  }
}

@media only screen and (min-width: 1360px) {
  .video-fix {
    padding-bottom: 10rem;
  }
}

@media only screen and (min-width: 1920px) {
  .video-fix {
    padding-bottom: 1rem;
  }
}
