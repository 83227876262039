.section-payment-details {
  background-color: #eef8ff;
  min-height: 100vh;
  
}

.payment-detail-info-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.02);
}

.payment-data h5 {
  color: #054c70;

  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.payment-data p {
  color: #505050;

  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 153.4%; /* 33.748px */
}

.payment-data {
  margin-bottom: 20px;
}

.purchased-item {
  border-radius: 10px;
  background: #eef8ff;
}


.amount-card{

    border-radius: 10px;
    background: #054c70;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.02);
}