$text-dark-gray : #505D68;
$text-light-gray : #A1AEB7;
$text-dark-blue: #054C70;
$mid-blue: #0089E5;

$hover-blue: #2f9ee7;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

body
{
    font-family: "Ubuntu", sans-serif;
}

.section-dark {
    background-color: $text-dark-blue;
    padding: 3rem;
    border-radius: 0 0 0 7rem;
    padding-top: 5rem;
    padding-bottom: 5rem;

}



.dropdown {
    background-color: #fff;
    border: none;
    border-radius: 0.2rem;
    height: 2rem;
    font-size: 0.9em;
    width: fit-content;

    color: #505D68;
    padding-left: 10px;
    padding-right: 10px;
    outline-color: #fff;

}

.dropdown:hover {
    background-color: rgb(200, 235, 255);
    border: none;
   
    outline-color: #fff;

    color: #505D68;
}

.dropdown:active {
    background-color: #fff;
    border: none;
  
   
    outline-color: #fff;
    color: #505D68;
}

.dropdown:focus {
    background-color: #fff;
    border: none;
   
    outline-color: #fff;
  
    color: #505D68;
}

select option:disabled {
    color: #FDFDFD;
    font-weight: 500;
   
}




.search-textbox {

    border: none;
    border-radius: 0.2rem;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 1em;

}

@media only screen and (max-width: 768px) {


    .dropdown {
      
        width:100%;
    
      
    
    }
   
  }