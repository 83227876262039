.font-family-class {
  font-family: "Ubuntu";
}

.policy-container {
  max-width: 1400px;
  margin: auto;
  padding: 0 100px 0 100px;
}

.policy-title {
  color: #054c70;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}

.description {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.sub-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.sub-description {
  color: #8d8d8d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

ul {
  padding-left: 1rem;
}

@media only screen and (max-width: 1919px) {
  .policy-container {
    max-width: 1200px;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .policy-container {
    padding: 0 20px 0 20px;
  }
}
