.about-card {
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    width: 100%;
}

.about-card-title {
    font-size: 18px;
    color: #505050;
    font-weight: 600;
}

.about-card-body {
    color: #505050;

    font-size: 18px;

    font-weight: 400;

}

.about-para {
    color: #505050;
    font-family: Ubuntu;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    /* 36.36px */
}

.about-card-icon {
    width: 70px;
    height: 70px;
}

.about-portfolio-card {
    border-radius: 4px;
    background: #FFF;
}

.about-portfolio-card>h3 {
 
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.about-portfolio-card > p{
color:  #000;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 122.222% */
}


.time-card  > h3{
    color:#505050;
  
    font-size: 18px;
    font-style: normal;
    font-weight: 700;


}



.time-card  > p{
    color: #8D8D8D;
  
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 0;
    margin: 0;


}
.time-card {
    display: flex;
    flex-direction: column;
    align-items: start; /* Center children horizontally */
    
    justify-content: center; /* Center children vertically */
    height:150px; 
    width: 100%;/* Ensure the card takes up the full height of its container if needed */
}


.hl{
    color: #0089E5;

    font-weight: 500;
  
}

.timeline-container1{
    height: 80vh; /* Set height to 80% of viewport height */
    overflow-y: hidden; /* Allow vertical scrolling */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
  }
  .timeline-container1::-webkit-scrollbar {
    display: none;
  }

@media screen and (max-width: 499px) {
    .time-card {
        display: flex;
        flex-direction: column;
        align-items: start; /* Center children horizontally */
        justify-content: center; /* Center children vertically */
        height:auto; /* Ensure the card takes up the full height of its container if needed */
    }
  }