.payment-gateway-section {
  min-height: calc(100vh - 241px);
  .font-family-class {
    font-family: "Ubuntu", sans-serif;
  }

  .form-control::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #a1aeb7;
  }

  .send-btn {
    padding: 10px 70px;
    float: right;
    border: none;
    background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
    transition: all 1s;
  }

  .send-btn:hover {
    background-image: linear-gradient(to right, #25d2fd, #054de7);
    transition: all 1s;
  }

  .send-btn:active {
    background-image: linear-gradient(to right, #41d2f7, #0345d3);
    transition: all 1s;
  }
}
