.primary-button-container {
  @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap");
  .primary-button,
  .primary-button:active {
    background: linear-gradient(107.41deg, #51d3f4 0%, #003fcc 100%);
    border: none;
    color: #fff;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    transition: 1s;
  }

  .drop-shadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .large-button {
    width: 220px;
    height: 75px;
  }
  .subscribe-button {
    height: 40px;
    width: 200px;
    font-size: 1em;
    font-weight: 100;
    color: #fff;
    font-family: "Ubuntu", sans-serif;
    font-weight: 200;
   
  }
}


.primary-button:hover{
  background-image: linear-gradient(to right, #25d2fd, #054de7);
  transition: 1s;


  .primary-button:active{
    background-image: linear-gradient(to right, #41d2f7, #0345d3);
    transition: 1s;
  }
}
