$text-dark-gray : #505D68;
$text-light-gray : #A1AEB7;
$text-dark-blue: #054C70;
$mid-blue: #0089E5;
$light-blue: #5DD9F9;
$hover-blue: #2f9ee7;

.course-card {
    border: none;
    box-shadow: 0px 0px 33px -5px rgba(0, 0, 0, 0.11);
    -webkit-box-shadow: 0px 0px 33px -5px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 0px 0px 33px -5px rgba(0, 0, 0, 0.11);

}



.course-type-tag {
   
    padding: 5px 5px 5px 5px;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 0.8em;
    text-align: center;




}

.course-difficulty-tag {

    padding: 1px;
    border-radius: 0.5rem;

    font-size: 0.8em;
}

.course-description-text {
  
    font-size: 1em;
}

.sub-text {
  
    font-weight: 600;
    margin: 0
}

.sub-description {
    margin: 0;
}

.gradient-button {
    background-image: linear-gradient(to right, #51D3F4, #003FCC);
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    transition: 0.3s;
    font-weight: 400;


}

.gradient-button:hover {
    background-image: linear-gradient(to right, #25d2fd, #054de7);
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    transition: 0.3s;

}

.gradient-button:active {
    background-image: linear-gradient(to right, #41d2f7, #0345d3);
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    transition: 0.3s;

}

.label-button {
    border: none;
    color: $mid-blue;
    font-weight: 500;
   text-decoration: none;
    background-color: #fff;
    transition: 0.3s;
}

.label-button:hover {
    border: none;
    color: #003FCC;
    font-weight: 500;
   text-decoration: none;
    background-color: #fff;
    transition: 0.3s;
}

.label-button:active {
    border: none;
    color: $text-dark-blue;
    font-weight: 500;
   text-decoration: none;
    background-color: #fff;
    transition: 0.3s;
}

.card-image {
    height: 100%;
}

.hr-card-m
{
    display: none;
}

.gradient-label{
    background-image: linear-gradient(to right, #51D3F4, #003FCC);
    

    
}
.title-link:hover{
    opacity: 0.85;
    transition: all 0.5s;
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .card-image {
        max-height: 200px;
        width: 100%;
    }

    .course-difficulty-tag {
        margin-top: 5px;
    }

    .course-type-tag {
      
        padding: 5px 5px 5px 5px;
        border-radius: 0.5rem;
        color: #fff;
        font-size: 1em;
        text-align: center;
        

    }

    .course-difficulty-tag {

        padding: 1px;
        border-radius: 0.5rem;
       width: 100%;
       
        font-size: 1em;
    }

    .reviews-count {
        font-size: 1em;
    }

    .course-description-text {
    
        font-size: 1em;
    }
    
    .sub-text {
      
        font-size: 1em;
        font-weight: 600;
        margin: 0
    }

    .hr-card-m
    {
        display: block;
        
        margin: 0 10px 0 10px;
    }

}
